import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export const Section2IconList = ({ children, dark }) => {
  return (
    <li className="check-icon-item">
      <div className="check-icon">
        <StaticImage
          className="icon-image"
          src={"../images/home/check-w.svg"}
          alt="check icon"
        />
      </div>

      <div className="item-content">
        <p className={dark ? "dark-font" : ""}>{children}</p>
      </div>
    </li>
  )
}
