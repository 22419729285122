import React from 'react'
import { Link } from 'gatsby'

const ServiceIconBoxes = () => {
  return (
    <ul className="row iconlist iconlist-icon-medium gap-10">
      {iconlist_data.map((item, index) => {
        return (
          <li className="col-12 col-md-3 col-lg" key={index}>
            <div
              style={{ borderTopColor: item.color, height: '100%' }}
              className="hover3 aligncenter shadow  vc_custom_1556078491460"
            >
              <div className="iconlist-item-icon"></div>
              <div className="iconlist-item-content">
                <h2 style={{ fontWeight: 300 }}>{item.title}</h2>

                <p>{item.subtitle}</p>
                <p>
                  <Link className="link" to={item.to}>
                    Click here
                  </Link>
                </p>
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

const iconlist_data = [
  {
    title: 'Business Concept Development',
    subtitle: 'Enhancing your business with crucial tech-related directives...',
    color: '#a951ed',
    to: '/services/business-concept-development'
  },
  {
    title: 'Managed Cloud Services',
    subtitle:
      'Efficient and affordable access to cloud computing and storage...',
    color: '#ff8bab',
    to: '/services/managed-cloud-service'
  },
  {
    title: 'Managed IT Services',
    subtitle: ' It maintenance and safety to a trusted third-party...',
    color: '#ffae0c',
    to: '/services/managed-it-service'
  },
  {
    title: 'IT Consultancy',
    subtitle:
      'An expert to guide you on the latest industry trends, essential...',
    color: '#02ccba',
    to: '/services/it-consultancy'
  },
  {
    title: 'Web Development',
    subtitle:
      'Building your website, the platform that showcases your business...',
    color: '#ff5483',
    to: '/services/web-development'
  }
]

export default ServiceIconBoxes
